import React, { useState, useEffect } from "react";
import NorthIcon from "@mui/icons-material/North";
import { isMobile } from "../localservices/utility.service";

const ScrollToTopButton = ({ scrollContainerRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollContainerRef.current?.scrollTop || 0;
      setIsVisible(scrollTop > 300);
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef]);

  const scrollToTop = () => {
    scrollContainerRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        style={{
          position: "fixed",
          bottom: isMobile() ? "100px" : "50px",
          right: isMobile() ? "10px" : "20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: isMobile() ? "30px" : "50px",
          height: isMobile() ? "30px" : "50px",
        }}
      >
        <NorthIcon fontSize="small" />
      </button>
    )
  );
};

export default ScrollToTopButton;
