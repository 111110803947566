import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const NotificationBanner = ({ data }) => {
  const [showBanner, setShowBanner] = React.useState(true);

  const handleClose = () => {
    setShowBanner(false);
  };

  return (
    showBanner && (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "rgb(68 190 195)", // Blue background
          color: "#fff",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        {/* Left Content */}
        <Box
          sx={{ display: "flex", alignItems: "center", gap: "8px", pl: "8px" }}
        >
          <InfoIcon />
          <Typography variant="body1" sx={{ fontFamily: "Open Sans" }}>
            A Copilot Session for{" "}
            <b>
              {data?.firstName} {data?.lastName}
            </b>
          </Typography>
        </Box>

        {/* Right Content */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    )
  );
};

export default NotificationBanner;
